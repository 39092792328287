import { graphql } from "gatsby"
import get from "lodash.get"
import React from "react"
import Layout from "../components/Layout"
import HeroV1 from "../components/slices/hero-v1"
import HeroV2 from "../components/slices/hero-v2"
import ContentV1 from "../components/slices/content-v1"
import ContentV2 from "../components/slices/content-v2"
import ContentV3 from "../components/slices/content-v3"
import Newsletter from "../components/slices/newsletter"
import HowEpicWorks from "../components/slices/how-epic-works"
import RecentWork from "../components/slices/recent-work"
import NewBrands from "../components/slices/new-brands"
import UpcomingEvents from "../components/slices/upcoming-events"
import AboutEpik from "../components/slices/about-epik"
import Statistics from "../components/slices/statistics"
import Brands from "../components/slices/brands"
import Map from "../components/slices/map"
import Team from "../components/slices/team"
import Testimonials from "../components/slices/testimonials"
import Business from "../components/slices/business"
import ContactForm from "../components/slices/contact-form"
import LinksSection from "../components/slices/links-section"
import Faq from "../components/slices/faq"
import Model from "../components/slices/model"

export default function Page({ data }) {
  const slices = get(data, "prismicPage.data.body")

  return (
    <Layout>
      {slices
        ? slices.map((slice, index) => {
            switch (slice.__typename) {
              case "PrismicPageBodyHero":
                return <HeroV1 key={index} data={slice} />
              case "PrismicPageBodyHeroV2":
                return <HeroV2 key={index} data={slice} />
              case "PrismicPageBodyContentV1":
                return <ContentV1 key={index} data={slice} />
              case "PrismicPageBodyContentV2":
                return <ContentV2 key={index} data={slice} />
              case "PrismicPageBodyConentV3":
                return <ContentV3 key={index} data={slice} />
              case "PrismicPageBodyHowEpicWorks":
                return <HowEpicWorks key={index} data={slice} />
              case "PrismicPageBodyNewsletter":
                return <Newsletter key={index} data={slice} />
              case "PrismicPageBodyAboutEpik":
                return <AboutEpik key={index} data={slice} />
              case "PrismicPageBodyStatistics":
                return <Statistics key={index} data={slice} />
              case "PrismicPageBodyBrands":
                return <Brands key={index} data={slice} />
              case "PrismicPageBodyTeam":
                return <Team key={index} data={slice} />
              case "PrismicPageBodyTestimonials":
                return <Testimonials key={index} data={slice} />
              case "PrismicPageBodyBusiness":
                return <Business key={index} data={slice} />

              case "PrismicPageBodyRecentWork":
                return <RecentWork key={index} data={slice} />
              case "PrismicPageBodyUpcomingEvents":
                return <UpcomingEvents key={index} data={slice} />
              case "PrismicPageBodyNewBrands":
                return <NewBrands key={index} data={slice} />
              case "PrismicPageBodyMap":
                return <Map key={index} data={slice} />
              case "PrismicPageBodyContactForm":
                return <ContactForm key={index} data={slice} />
              case "PrismicPageBodyLinksSection":
                return <LinksSection key={index} data={slice} />
              case "PrismicPageBodyFaq":
                return <Faq key={index} data={slice} />
              case "PrismicPageBodyModel":
                return <Model key={index} data={slice} />
              default:
                return <pre>{JSON.stringify(slice, null, 2)}</pre>
            }
          })
        : null}
    </Layout>
  )
}
export const query = graphql`
  query($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        body {
          ... on PrismicPageBodyHero {
            id
            primary {
              background_image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyHeroV2 {
            primary {
              text {
                html
              }
              background_image {
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyContentV1 {
            id
            slice_type
            primary {
              button_link
              button_text
              image {
                alt
                fluid(imgixParams: { q: 90 }) {
                  ...GatsbyPrismicImageFluid
                }
              }
              text {
                html
              }
              text_side
            }
          }
          ... on PrismicPageBodyContentV2 {
            id
            primary {
              button_link
              button_text
              text_side
              image {
                alt
                dimensions {
                  width
                }
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyHowEpicWorks {
            id
            items {
              point_text
              point_title
            }
            primary {
              title {
                html
              }
            }
          }
          ... on PrismicPageBodyConentV3 {
            primary {
              button_label
              button_link
              text_aligned
              text {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyNewsletter {
            primary {
              secondary_text {
                html
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyRecentWork {
            id
            primary {
              title: title1 {
                text
              }
            }
            items {
              item {
                uid
                document {
                  ... on PrismicPost {
                    data {
                      thumbnail: featured_image {
                        fluid(imgixParams: { q: 90 }) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      date(formatString: "DD/MM/YYYY")
                      name: title {
                        text
                      }
                      excerpt {
                        html
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyNewBrands {
            id
            primary {
              background
              text {
                html
              }
              title {
                html
              }
            }
            items {
              logo {
                dimensions {
                  width
                }
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyAboutEpik {
            id
            items {
              text
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              title
            }
            primary {
              title {
                text
              }
              sidebar_text {
                html
              }
              sidebar_title {
                html
              }
              sidebar_componente
              sidebar_button_text
              sidebar_button_link
            }
          }
          ... on PrismicPageBodyBrands {
            id
            items {
              logos {
                dimensions {
                  width
                }
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            primary {
              layout
              title {
                html
              }
            }
          }
          ... on PrismicPageBodyUpcomingEvents {
            id
            primary {
              title {
                text
              }
            }
            items {
              item {
                document {
                  ... on PrismicPost {
                    data {
                      thumbnail: featured_image {
                        fluid(imgixParams: { q: 90 }) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      date(formatString: "DD/MM/YYYY")
                      title {
                        text
                      }
                      excerpt {
                        text
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTeam {
            id
            items {
              position
              name
              link
              image {
                fixed(height: 200, width: 200) {
                  ...GatsbyPrismicImageFixed
                }
              }
            }
          }
          ... on PrismicPageBodyTestimonials {
            id
            primary {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
            items {
              testimonial {
                html
              }
            }
          }
          ... on PrismicPageBodyBusiness {
            id
            items {
              column
              image {
                url
              }
              column_text: text {
                html
              }
            }
            primary {
              title: title1 {
                html
              }
              right_column_title
              left_column_title
              left_column_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              right_column_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyStatistics {
            id
            items {
              label
              numbers
            }
          }
          ... on PrismicPageBodyMap {
            id
            primary {
              zoom
              latitude
              longitude
            }
          }
          ... on PrismicPageBodyContactForm {
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyLinksSection {
            id
            primary {
              title: title1 {
                html
              }
            }
            items {
              text_columns {
                html
              }
            }
          }
          ... on PrismicPageBodyFaq {
            id
            primary {
              title: title1 {
                html
              }
            }
            items {
              question
              answer {
                html
              }
            }
          }
          ... on PrismicPageBodyModel {
            id
            primary {
              title1 {
                html
              }
            }
            items {
              first_column_centered
              col1
              col2
              col3
              col4
              col5
              col6
              col7
            }
          }
        }
      }
    }
  }

  fragment EventDetails on PrismicEvent {
    id
    uid
    data {
      name {
        text
      }
      background {
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
      thumbnail {
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyPrismicImageFluid
        }
      }

      end_date(formatString: "MMMM D, YYYY")
      release_date(formatString: "MMMM D, YYYY")
      region
      platforms
      logo_group {
        logo {
          dimensions {
            width
          }
          fluid(maxWidth: 100) {
            ...GatsbyPrismicImageFluid
          }
        }
        logo_link {
          type
          uid
        }
      }
      game {
        uid
        document {
          ... on PrismicGame {
            id
            data {
              title {
                text
              }
            }
          }
        }
      }
      brand_owner {
        document {
          ... on PrismicBrandOwner {
            id
            uid
            data {
              name {
                text
              }
            }
          }
        }
      }
      game_publisher {
        uid
        document {
          ... on PrismicPublisher {
            id
            uid
            data {
              name {
                text
              }
            }
          }
        }
      }
      video {
        html
      }
      brand {
        uid
        document {
          ... on PrismicBrand {
            id
            data {
              name {
                text
              }
            }
          }
        }
      }
      item_list {
        item {
          document {
            ... on PrismicItem {
              id
              data {
                background_image {
                  fixed(height: 100, width: 100) {
                    ...GatsbyPrismicImageFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
