import get from "lodash.get"
import React, { useState } from "react"
import {
  BsCaretDown,
  BsCaretDownFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs"
import Collapse from "../Collapse"

export default function Faq({ data }) {
  const [isOpen, setIsOpen] = useState(null)
  const splitAt = data.items && Math.ceil(data.items.length / 2)
  return (
    <>
      <section className="bg-primary-dark pt-12 pb-16 lg:pt-20 lg:pb-24 font-mono">
        <div className="container">
          <div
            className="text-center prose prose-no-h-margins"
            dangerouslySetInnerHTML={{
              __html: get(data, "primary.title.html"),
            }}
          ></div>
          <div className="faq mt-10">
            {" "}
            <div>
              {data.items &&
                data.items.slice(0, splitAt).map((qa, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b border-white border-opacity-25"
                      onClick={() => {
                        isOpen !== index ? setIsOpen(index) : setIsOpen(null)
                      }}
                    >
                      <div className="flex py-5   cursor-pointer ">
                        <BsFillQuestionCircleFill className="mr-2" />
                        <h6 className="leading-none">
                          {get(qa, "question", "")}
                        </h6>
                        <BsCaretDownFill className="ml-auto" />
                      </div>
                      <Collapse isOpen={index == isOpen}>
                        <div
                          className="pb-6"
                          dangerouslySetInnerHTML={{
                            __html: get(qa, "answer.html", ""),
                          }}
                        ></div>
                      </Collapse>
                    </div>
                  )
                })}
            </div>
            <div>
              {data.items &&
                data.items.slice(splitAt).map((qa, index) => {
                  const i = index + splitAt
                  return (
                    <div
                      key={i}
                      className="border-b border-white border-opacity-25"
                      onClick={() => {
                        isOpen !== i ? setIsOpen(i) : setIsOpen(null)
                      }}
                    >
                      <div className="flex py-4   cursor-pointer ">
                        <BsFillQuestionCircleFill className="mr-2" />
                        <h6 className="leading-none">
                          {get(qa, "question", "")}
                        </h6>
                        <BsCaretDownFill className="ml-auto" />
                      </div>
                      <Collapse isOpen={i == isOpen}>
                        <div
                          className="pb-6"
                          dangerouslySetInnerHTML={{
                            __html: get(qa, "answer.html", ""),
                          }}
                        ></div>
                      </Collapse>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
