import GatsbyBackgroundImage from "gatsby-background-image";
import get from "lodash.get";
import React from "react";
import { FaChevronRight } from "react-icons/fa";

export default function HeroV2({ data }) {
  const text = get(data, "primary.text.html", "");
  const image = get(data, "primary.background_image.fluid");
  return (
    <>
      {" "}
      <GatsbyBackgroundImage
        fluid={[image || "linear-gradient(transparent,transparent)"]}
      >
        <section className="mt-0 section-b-sm hero-section-overlay">
          <div className="container flex items-center pt-64 pb-48 phone:pt-56">
            <div className="w-full row">
              <div
                className={
                  "col w-full tablet:order-first  lg:w-1-2 md:w-3-4  flex-grow md:max-w-xl "
                }
              >
                <div
                  className="space-y-5 prose p-opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                ></div>
                <form
                  className="flex items-stretch mt-10 phone:flex-col phone:space-y-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target);
                    const email = formData.get("email");
                    fetch("https://api.epik.gg/v1/user/utm-sessions", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        session_id: "epik-gg-form",
                        label: "epik-gg-form",
                        custom_data: {
                          email,
                        },
                      }),
                    }).then((res) => {
                      if (res.status === 200) {
                        // reload
                        window.location.reload();
                      }
                    });
                  }}
                >
                  <input
                    type="hidden"
                    id="typeform-welcome"
                    name="typeform-welcome"
                    value="0"
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email address"
                    required
                    className="flex-grow"
                  ></input>
                  <button type="submit">
                    Get Started <FaChevronRight className="ml-2" />{" "}
                  </button>
                </form>
              </div>
              <div className="hidden col md:flex lg:w-1-2 md:w-1-4"></div>
            </div>
          </div>
        </section>
      </GatsbyBackgroundImage>
    </>
  );
}
