import React from "react";
import GatsbyBackgroundImage from "gatsby-background-image";
import cn from "classnames";
import get from "lodash.get";

import Link from "../Link";
import GatsbyImage from "gatsby-image";
export default function ContentV1({ data }) {
  const button_link = get(data, "primary.button_link");
  const button_text = get(data, "primary.button_text");
  const text_side = get(data, "primary.text_side");
  const text = get(data, "primary.text.html", "");
  const image = get(data, "primary.image.fluid");
  const imageAlt = get(data, "primary.image.alt");
  const Tag = button_link ? "a" : "div";
  return (
    <>
      {" "}
      <section>
        <div className="container ">
          <div className="items-center row ">
            <div className="w-full col lg:w-1-2 lg:px-20 ">
              <Tag
                {...{
                  className: "block",
                  href: button_link || undefined,
                }}
              >
                {image && (
                  <GatsbyImage
                    style={{
                      maxWidth: get(
                        data,
                        "primary.image.dimensions.width",
                        "100%"
                      ),
                    }}
                    className="mx-auto"
                    fluid={image}
                    alt={imageAlt}
                  />
                )}
              </Tag>
            </div>
            <div
              className={cn(
                "col w-full lg:w-1-2 lg:px-20 py-20 tablet:order-last",
                {
                  "order-first": text_side == "Left",
                }
              )}
            >
              <div
                className="space-y-5 prose p-opacity-80 content-images-inline"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></div>
              {button_text && (
                <Link to={button_link} className="mt-10 button">
                  {button_text}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
