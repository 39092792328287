import get from "lodash.get"
import React from "react"
import cn from "classnames"
import "react-perfect-scrollbar/dist/css/styles.css"
import PerfectScrollbar from "react-perfect-scrollbar"
export default function Model({ data }) {
  return (
    <>
      <section>
        <div className="container">
          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: get(data, "primary.title1.html"),
            }}
          ></div>

          <PerfectScrollbar
            options={{
              suppressScrollY: true,
            }}
          >
            <div className="model-table pb-8">
              {data.items &&
                data.items.map((row, index) => {
                  return (
                    <div className={`contents row-${index}`}>
                      {Object.keys(row).length > 1 &&
                        Object.keys(row)
                          .slice(1)
                          .map((col, i) => {
                            return (
                              <div
                                style={{
                                  textAlign: col == "col1" && "left",
                                }}
                                className={cn(
                                  "py-4 px-8 text-center leading-tight font-semibold",
                                  {
                                    "fist-column-padding":
                                      col == "col1" &&
                                      row.first_column_centered,
                                    "bg-primary-dark": index % 2 == 0,
                                    "bg-opacity-50": [2, 8, 14].includes(index),
                                  }
                                )}
                                key={i}
                              >
                                {row[col]}
                              </div>
                            )
                          })}
                    </div>
                  )
                })}
            </div>
          </PerfectScrollbar>
        </div>
      </section>
    </>
  )
}
